import React from 'react';
import styles from './appointment-list.module.scss';
import { useAppSelector } from '../../hooks/useRedux';
import { getDateAsString } from '../../utils';
import { IVisit } from '../../types/visits.types';

interface IAppointmentList {
  items: Array<IVisit>,
}

const AppointmentList = ({ items }: IAppointmentList): JSX.Element => {
  const authReducer = useAppSelector((store) => store.authReducer);
  const userType = authReducer.user?.company.type;

  function sort(arr: Array<IVisit>) {
    return arr.sort((a: IVisit, b: IVisit) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
  }
  return (
    <div className={styles.appointmentList}>
      <ul>
        {sort(items).map((d, i) => <li key={`item-${d._id}-${i}`}>
          <div className={(d.visit) ? `${styles.item} ${styles.done}` : styles.item}>
            <div className={styles.date}>
              <p>{getDateAsString(d.startDate)}</p>
            </div>
            <div className={styles.provider}>
              <p>
                {
                  userType === 'magasin'
                    ? d.provider.name
                    : d.store.name
                }
              </p>
            </div>
          </div>
        </li>)}
      </ul>
    </div>
  );
};

export default AppointmentList;
