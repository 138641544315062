/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getData } from '.';
import { DispatchType } from '../types';
import {
  CREATE_VISIT,
  GET_VISITS,
  LOADING_VISIT,
  GET_RANKING_PROVIDER,
  GET_RANKING_MAGASIN,
  ERROR_VISIT,
} from './actions';

const API_URL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createVisit = async (dispatch: DispatchType, data: any): Promise<any> => {
  const requestUrl = `${API_URL}/visit`;
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `${token}` },
  };
  const promise = await axios.post(requestUrl, data, config);
  if (promise.data.visit) {
    dispatch({
      type: CREATE_VISIT,
      payload: {
        ...promise.data.visit,
        provider: promise.data.provider,
      },
    });
  }
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getVisits = async (dispatch: DispatchType): Promise<any> => {
  const url = '/meeting';
  dispatch({
    type: LOADING_VISIT,
    payload: GET_VISITS,
  });
  const response = await getData(ERROR_VISIT, url, dispatch, true);
  if (response.data.meetings) {
    dispatch({
      type: GET_VISITS,
      payload: response.data.meetings,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRankingMagasins = async (dispatch: DispatchType): Promise<any> => {
  const url = '/visit/ranking/magasin';
  dispatch({
    type: LOADING_VISIT,
    payload: GET_RANKING_MAGASIN,
  });
  const response = await getData(ERROR_VISIT, url, dispatch, true);
  if (response.data.companies) {
    dispatch({
      type: GET_RANKING_MAGASIN,
      payload: response.data.companies,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRankingProviders = async (dispatch: DispatchType): Promise<any> => {
  const url = '/visit/ranking/fournisseur';
  dispatch({
    type: LOADING_VISIT,
    payload: GET_RANKING_PROVIDER,
  });
  const response = await getData(ERROR_VISIT, url, dispatch, true);
  if (response.data.companies) {
    dispatch({
      type: GET_RANKING_PROVIDER,
      payload: response.data.companies,
    });
  }
};
