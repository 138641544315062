import React, { useEffect } from 'react';
import styles from './collaborators.module.scss';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { getCollaborators } from '../../actions/collaborators';
import CollaboratorsList from '../../components/CollaboratorsList';

const Collaborators = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const collaboratorsReducer = useAppSelector((state) => state.collaboratorsReducer);
  const authReducer = useAppSelector((state) => state.authReducer);
  const { collaboratorsList } = collaboratorsReducer;

  useEffect(() => {
    if (authReducer.user?.company._id) {
      getCollaborators(dispatch, authReducer.user?.company._id);
    }
  }, []);

  return (
    <div className={styles.collaborators}>
      <div className={styles.container}>
        <CollaboratorsList collaborators={collaboratorsList || []}/>
      </div>
    </div>
  );
};

export default Collaborators;
