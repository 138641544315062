// AUTH ACTIONS
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_GET_BADGE = 'AUTH_GET_BADGE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_LOADING_TYPE = 'LOADING_TYPE';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
// PROVIDERS ACTIONS
export const LOADING_PROVIDERS = 'LOADING_PROVIDERS';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const ERROR_PROVIDERS = 'ERROR_PROVIDERS';
// COLLABORATORS ACTIONS
export const LOADING_COLLABORATORS = 'LOADING_COLLABORATORS';
export const GET_COLLABORATORS = 'GET_COLLABORATORS';
export const ERROR_COLLABORATORS = 'ERROR_COLLABORATORS';
// VISIT ACTIONS
export const CREATE_VISIT = 'CREATE_VISIT';
export const GET_VISITS = 'GET_VISITS';
export const GET_RANKING_PROVIDER = 'GET_RANKING_PROVIDER';
export const GET_RANKING_MAGASIN = 'GET_RANKING_MAGASIN';
export const LOADING_VISIT = 'LOADING_VISIT';
export const ERROR_VISIT = 'ERROR_VISIT';
export const RESET_TRIGGER_QRCODE = 'RESET_TRIGGER_QRCODE';
export const TRIGGER_QRCODE = 'TRIGGER_QRCODE';
