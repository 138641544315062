import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useAppDispatch } from '../../hooks/useRedux';
import { logout } from '../../actions/auth';
import BottomBar from '../BottomBar';
import TopRanking from '../TopRanking';
import NextAppointment from '../NextAppointment';
import styles from './layout.module.scss';
import logoutImg from '../../assets/images/icons/logout.svg';
import collaboratorsIcon from '../../assets/images/icons/collaborators.svg';
import logoImg from '../../assets/images/logoLeclerc.png';
import * as animationData from './notification.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  function getHeaderContent() {
    let elts = null;
    if (location.pathname === '/collaborateurs') {
      elts = [
        <h1 key='title-ranking'>Collaborateurs</h1>,
        <div key='contributors-bg' className={styles['contributors-bg']}>
          <div className={styles.logo}>
            <img src={logoImg} alt="logo leclerc" />
          </div>
        </div>,
      ];
    } else if (location.pathname === '/classement') {
      elts = [
        <h1 key='title-ranking'>Classement</h1>,
        <TopRanking key='top-ranking' />,
      ];
    } else if (location.pathname === '/rendez-vous') {
      elts = [
        <h1 key='title-appointment'>Rendez-vous</h1>,
        <NextAppointment key='next-appointment' />,
      ];
    } else if (location.pathname === '/qrcode') {
      elts = [
        <h1 key='title-qrcode'>Mon QRcode</h1>,
        <div key='lottie' className={styles.animation}>
          <Lottie options={defaultOptions}
            height={300}
            width={300}
          />
        </div>,
      ];
    }
    return elts;
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={logoImg} alt="logo leclerc" />
        </div>
        <div className={styles.containerBlurShape}>
          <div className={styles.blurShape}></div>
        </div>
        {getHeaderContent()}
        {location.pathname === '/collaborateurs'
          ? <img className={styles.logout} onClick={() => logout(dispatch)} src={logoutImg} alt="bouton logout" />
          : (
            <NavLink className={styles.initials} to='/collaborateurs'>
              <img src={collaboratorsIcon} alt="collaborateurs" />
            </NavLink>
          )
        }
      </div>
      <div className={styles.container}>
        <div className='roundedContainer'>
          {children}
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default Layout;
