import React, { useEffect } from 'react';
import { GET_RANKING_MAGASIN, GET_RANKING_PROVIDER } from '../../actions/actions';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { getRankingMagasins, getRankingProviders } from '../../actions/visit';
import Loader from '../../components/Loader';
import RankingList from '../../components/RankingList';
import styles from './ranking.module.scss';

const Ranking = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const authReducer = useAppSelector((store) => store.authReducer);
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const { user } = authReducer;
  const type = user?.company?.type;
  const { rankingMagasins, rankingProviders } = visitsReducer;
  const ranking = type === 'fournisseur' ? rankingProviders : rankingMagasins;
  const loadingAction = type === 'fournisseur' ? GET_RANKING_PROVIDER : GET_RANKING_MAGASIN;

  useEffect(() => {
    if (type === 'fournisseur') {
      getRankingProviders(dispatch);
    } else {
      getRankingMagasins(dispatch);
    }
  }, []);

  return (
    <div className={styles.ranking}>
      <div className={styles.container}>
        {visitsReducer.isLoading.includes(loadingAction) && <Loader/>}
        <RankingList userCompany={user?.company?._id} items={ranking.slice(3, ranking.length)} />
      </div>
    </div>
  );
};

export default Ranking;
