import {
  combineReducers,
  createStore,
  Reducer,
  Store,
  compose,
} from '@reduxjs/toolkit';
import { ApplicationState, Action, DispatchType } from '../types';

import authReducer from './auth/auth.reducer';
import visitsReducer from './visits/visits.reducer';
import collaboratorsReducer from './collaborators/collaborators.reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  authReducer,
  visitsReducer,
  collaboratorsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<ApplicationState, Action> & {
  dispatch: DispatchType
} = createStore(
  reducers,
  composeEnhancers(),
);
