import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import { animated, useSpring } from '@react-spring/web';
import styles from './modal.module.scss';

interface IModalStandalone {
  children: React.ReactNode,
  handleClose?: () => void,
  handleOpen?: () => void,
  domNode?: string,
}

const ModalStandalone = forwardRef(({
  children,
  handleClose,
  handleOpen,
  domNode = 'modal',
} : IModalStandalone, ref):JSX.Element => {
  const modalRoot = document.getElementById(domNode);
  const [open, setOpen] = useState(false);
  const [toggleAnimation, setToggleAnimation] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: (): void => setOpen((state) => !state),
    open: (): void => setOpen(true),
    close: (): void => setOpen(false),
    isOpen: (): boolean => open,
  }));

  function toggle() { setOpen((state) => !state); }

  function close() {
    setToggleAnimation(false);
  }

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!ref) console.error('ModalStandalone components need require a ref');
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (open) {
      if (handleOpen) handleOpen();
      setToggleAnimation(true);
    }
    if (open === false && handleClose) handleClose();
  }, [open]);

  return (
    <>
      {modalRoot
        && <>
          {createPortal(
            <>
              {(ref && open)
                && <>
                  <animated.div
                    style={{ ...spring }}
                    className={styles['overlay-modal']}
                    onClick={close}
                  />
                  <animated.div
                    style={{ ...spring }}
                    className={styles.modal}
                  >
                    {children}
                  </animated.div>
                </>

              }
            </>,
            modalRoot,
          )}
        </>
      }
    </>
  );
});

export default ModalStandalone;
