import { Action } from '../../types';
import {
  LOADING_COLLABORATORS,
  GET_COLLABORATORS,
  ERROR_COLLABORATORS,
} from '../../actions/actions';
import { ICollaboratorsState } from '../../types/collaborators.types';

const initialState: ICollaboratorsState = {
  collaboratorsList: null,
  error: null,
  isLoading: false,
};

const visitsReducer = (
  state: ICollaboratorsState = initialState,
  action: Action,
): ICollaboratorsState => {
  let updatedState = { ...state };
  switch (action.type) {
    case GET_COLLABORATORS:
      updatedState = { ...state, collaboratorsList: action.payload, isLoading: false };
      break;
    case LOADING_COLLABORATORS:
      updatedState = { ...state, isLoading: true };
      break;
    case ERROR_COLLABORATORS:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default visitsReducer;
