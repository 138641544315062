import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { getDateAsString } from '../../utils';
import { useAppSelector } from '../../hooks/useRedux';
import Loader from '../Loader';
import styles from './next-appointment.module.scss';

const getLastNextMeeting = async () => {
  try {
    const config = { headers: { Authorization: `${localStorage.getItem('token')}` } };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/meeting`, config);
    const data = await response.json();
    // && (new Date(meeting.startDate).getTime() > Date.now())
    let meetings = (data.meetings || []).filter((meeting) => !meeting.visit);
    meetings = meetings.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    return meetings[0] || null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const NextAppointment = () => {
  const [nextAppointment, setNextAppointment] = useState();
  const authReducer = useAppSelector((store) => store.authReducer);
  const userType = authReducer.user.company.type;

  useEffect(() => {
    getLastNextMeeting().then((res) => {
      setNextAppointment(res);
    });
  }, []);

  return (
    <div className={styles.nextAppointment}>
      {nextAppointment === undefined
        ? <Loader />
        : <>
          {nextAppointment && (
            <>
              <div className={styles.title}>
                <AiOutlineClockCircle />
                <p>Votre prochain render-vous</p>
              </div>
              <p className={styles.date}>
                {getDateAsString(nextAppointment.startDate)}
              </p>
              <h1>{
                userType === 'magasin'
                  ? nextAppointment.provider.name
                  : nextAppointment.store.name
              }</h1>
            </>
          )}
        </>
      }
    </div >
  );
};

export default NextAppointment;
