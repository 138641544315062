import React, { createRef, useLayoutEffect } from 'react';
import styles from './ranking-list.module.scss';
import { IRank } from '../../types/visits.types';

interface IRankingList {
  items: Array<IRank>,
  userCompany: string,
}

const RankingList = ({ items, userCompany }: IRankingList): JSX.Element => {
  const listRef = createRef<HTMLUListElement>();
  useLayoutEffect(() => {
    const target = document.getElementById('active');
    if (target && listRef.current) {
      const scrollTarget = target.offsetTop - (listRef.current.offsetHeight / 2) + 29;
      listRef.current?.scrollTo(0, scrollTarget);
    }
  }, [items]);
  return (
    <div className={styles.rankingList}>
      <ul ref={listRef}>
        {items.map((d, i) => <li key={`item-${d.name}-${i}`}>
          <div id={d._id === userCompany ? 'active' : ''} className={`${styles.item} ${d._id === userCompany ? styles.active : ''}`}>
            <div className={styles.index}>
              <p>{i + 4}</p>
            </div>
            <div className={styles.provider}>
              <p>{d.name}</p>
              <p className={styles.countVisits}>{d.countVisits} visite{d.countVisits > 1 && 's'}</p>
            </div>
            <div className={styles.score}>
              <p>{d.score} pts</p>
            </div>
          </div>
        </li>)}
      </ul>
    </div>
  );
};

export default RankingList;
