import { Action } from '../../types';
import {
  CREATE_VISIT,
  GET_VISITS,
  GET_RANKING_MAGASIN,
  GET_RANKING_PROVIDER,
  LOADING_VISIT,
  TRIGGER_QRCODE,
  RESET_TRIGGER_QRCODE,
  ERROR_VISIT,
} from '../../actions/actions';
import { IVisitsState, IRank } from '../../types/visits.types';

const initialState: IVisitsState = {
  visit: null,
  visits: [],
  rankingMagasins: [],
  rankingProviders: [],
  error: null,
  triggerQRCode: null,
  isLoading: [],
};

const visitsReducer = (
  state: IVisitsState = initialState,
  action: Action,
): IVisitsState => {
  let updatedState = { ...state };
  let errorMessage = null;
  switch (action.type) {
    case TRIGGER_QRCODE:
      updatedState = {
        ...state, triggerQRCode: true, visit: null, error: null,
      };
      break;
    case RESET_TRIGGER_QRCODE:
      updatedState = { ...state, triggerQRCode: null };
      break;
    case CREATE_VISIT:
      updatedState = {
        ...state, visit: action.payload, isLoading: state.isLoading?.filter((d) => d !== CREATE_VISIT), error: null,
      };
      break;
    case GET_VISITS:
      updatedState = {
        ...state, visits: action.payload, isLoading: state.isLoading?.filter((d) => d !== GET_VISITS), error: null,
      };
      break;
    case GET_RANKING_PROVIDER:
      updatedState = {
        ...state,
        rankingProviders: action.payload.sort((a: IRank, b: IRank) => b.score - a.score),
        isLoading: state.isLoading?.filter((d) => d !== GET_RANKING_PROVIDER),
        error: null,
      };
      break;
    case GET_RANKING_MAGASIN:
      updatedState = {
        ...state,
        rankingMagasins: action.payload.sort((a: IRank, b: IRank) => b.score - a.score),
        isLoading: state.isLoading?.filter((d) => d !== GET_RANKING_MAGASIN),
        error: null,
      };
      break;
    case LOADING_VISIT:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload], error: null };
      break;
    case ERROR_VISIT:
      if (action.payload?.response?.status === 404) {
        errorMessage = 'Fournisseur introuvable, êtes-vous bien sur le bon salon ?';
      } else {
        errorMessage = 'Une erreur s\'est produite, veuillez ressayer.';
      }
      updatedState = { ...state, error: errorMessage || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default visitsReducer;
