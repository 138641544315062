import React, { useEffect } from 'react';
import { GET_VISITS } from '../../actions/actions';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { getVisits } from '../../actions/visit';
import Loader from '../../components/Loader';
import styles from './appointment.module.scss';
import AppointmentList from '../../components/AppointmentList';

const Appointment = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const { visits } = visitsReducer;
  useEffect(() => {
    getVisits(dispatch);
  }, []);

  return (
    <div className={styles.appointment}>
      <div className={styles.container}>
        {visitsReducer.isLoading.includes(GET_VISITS) && <Loader />}
        {visits && <AppointmentList items={visits} />}
      </div>
    </div>
  );
};

export default Appointment;
