import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useAppSelector } from '../../hooks/useRedux';
import styles from './collaborators-list.module.scss';
import { getDateAsString } from '../../utils';
import { ICollaborator } from '../../types/collaborators.types';

interface ICollaboratorsList {
  collaborators: Array<ICollaborator>,
}

const CollaboratorsList = ({ collaborators }: ICollaboratorsList): JSX.Element => {
  const authReducer = useAppSelector((store) => store.authReducer);
  const { user: currentUser } = authReducer;
  const [usersOpened, setUsersOpened] = useState<string[]>([]);

  function toggle(id: string) {
    if (usersOpened.includes(id)) {
      setUsersOpened((state) => state.filter((d) => d !== id));
    } else {
      setUsersOpened((state) => [...state, id]);
    }
  }

  function sort(arr: Array<ICollaborator>): Array<ICollaborator> {
    let authUser;
    const users = arr.filter((u: ICollaborator) => {
      if (u._id === currentUser?._id) authUser = u;
      return u._id !== currentUser?._id;
    });
    return authUser ? [authUser, ...users] : [];
  }

  return (
    <div className={styles.collaborators}>
      <ul className={styles['list-users']}>
        {((sort(collaborators)) || []).map((user) => (
          <li key={`user-${user._id}`}>
            <div className={styles.item} onClick={() => toggle(user._id)}>
              {user._id === currentUser?._id && <div className={styles.account}><div><p>Mon compte</p></div></div>}
              <p className={styles.user}>
                {(user.visits.length > 0) && (
                  <>
                    {usersOpened.includes(user._id) ? <span className={styles.up}><BiChevronUp size={'24px'} /></span> : <span className={styles.down}><BiChevronDown size={'24px'} /></span>}
                  </>
                )}
                {user.profile.firstName} {user.profile.lastName}
              </p>
              <span className={styles.count}>
                <p>{user.visits.length}</p>
              </span>
            </div>
            {(user.visits.length > 0 && usersOpened.includes(user._id)) && (
              <ul className={styles['list-visits']}>
                {user.visits.map((visit) => (
                  <li key={`visit-${visit._id}`}>
                    <p>{visit.provider?.name}</p>
                    <span className={styles.date}>
                      <p>{getDateAsString(visit.startDate)}</p>
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CollaboratorsList;
