import axios from 'axios';
import { postData, getData } from './index';
import { DispatchType } from '../types';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_GET_BADGE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_LOADING_TYPE,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from './actions';
import {
  IUser,
  ISignIn,
  IForgotPassword,
  IResetPassword,
} from '../types/auth.types';

const API_URL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signIn = async (dispatch: DispatchType, data: ISignIn): Promise<any> => {
  const url = '/auth/login';
  let user: IUser | null = null;
  let token = null;
  dispatch({
    type: AUTH_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    user = response.data.user;
    token = response.data.token;
  });

  if (token && user!.company) {
    const url2 = `${API_URL}/company/${user!.company}`;
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    const responseCompany = await axios.get(url2, config);
    if (responseCompany.data.company) {
      user!.company = responseCompany.data.company;
    } else {
      user = null;
    }
  }

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forgotPassword = async (dispatch: DispatchType, data: IForgotPassword): Promise<any> => {
  const url = '/auth/forgot-password';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    console.log(response);
  });
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resetPassword = async (dispatch: DispatchType, data: IResetPassword): Promise<any> => {
  const url = `/auth/reset-password/${data.token}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false).then((response: any) => {
    console.log(response);
  });
  return promise;
};

export const logout = (dispatch: DispatchType): void => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProfileAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  const companyId = localStorage.getItem('company');
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let company: any = null;

  if (companyId && token) {
    const url2 = `${API_URL}/company/${companyId}`;
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    const responseCompany = await axios.get(url2, config);
    if (responseCompany.data.company) {
      company = responseCompany.data.company;
    } else {
      logout(dispatch);
    }
  } else {
    logout(dispatch);
  }

  if (response.data.user && company) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: { ...response.data.user, company },
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserBadge = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/user/qrcode/${id}`;
  dispatch({
    type: AUTH_LOADING_TYPE,
    payload: 'get_badge',
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data.code) {
    dispatch({
      type: AUTH_GET_BADGE,
      payload: response.data,
    });
  }
};

export const resetError = (dispatch: DispatchType): void => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};
