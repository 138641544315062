import React from 'react';
import { GET_RANKING_MAGASIN, GET_RANKING_PROVIDER } from '../../actions/actions';
import { useAppSelector } from '../../hooks/useRedux';
import Loader from '../Loader';
import styles from './top-ranking.module.scss';
import { IRank } from '../../types/visits.types';

const TopRanking = (): JSX.Element => {
  const authReducer = useAppSelector((store) => store.authReducer);
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const userCompany = authReducer.user?.company;
  const type = userCompany?.type;
  const { rankingMagasins, rankingProviders }: { rankingMagasins: Array<IRank>, rankingProviders: Array<IRank> } = visitsReducer;
  const ranking = type === 'fournisseur' ? rankingProviders : rankingMagasins;
  const loadingAction = type === 'fournisseur' ? GET_RANKING_PROVIDER : GET_RANKING_MAGASIN;

  return (
    <div className={styles.topranking}>
      <div className={styles['container-sun']}>
        <div className={styles.halo}></div>
        <div className={styles.sun}>
          {Array(16).fill(null).map((d, i) => <div key={`sun-ray-${i}`} className={styles.sunRay}></div>)}
        </div>
      </div>
      <div className={styles.podium}>
        <div className={userCompany._id === ranking[1]?._id ? styles.active : ''}>
          {visitsReducer.isLoading.includes(loadingAction)
            ? <span className={styles.loader}><Loader size={25} /></span>
            : <><p className={styles.score}>{ranking[1]?.score} pts</p><p className={styles.name}>{ranking[1]?.name}</p></>}
          <div className={styles.stud}>
            <p>2</p>
          </div>
        </div>
        <div className={userCompany._id === ranking[0]?._id ? styles.active : ''}>
          {visitsReducer.isLoading.includes(loadingAction)
            ? <span className={styles.loader}><Loader size={25} /></span>
            : <><p className={styles.score}>{ranking[0]?.score} pts</p><p className={styles.name}>{ranking[0]?.name}</p></>}
          <div className={styles.stud}>
            <p>1</p>
          </div>
        </div>
        <div className={userCompany._id === ranking[2]?._id ? styles.active : ''}>
          {visitsReducer.isLoading.includes(loadingAction)
            ? <span className={styles.loader}><Loader size={25} /></span>
            : <><p className={styles.score}>{ranking[2]?.score} pts</p><p className={styles.name}>{ranking[2]?.name}</p></>}
          <div className={styles.stud}>
            <p>3</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRanking;
