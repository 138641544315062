/* eslint-disable import/prefer-default-export */
import { getData } from '.';
import { DispatchType } from '../types';
import {
  LOADING_COLLABORATORS,
  GET_COLLABORATORS,
  ERROR_COLLABORATORS,
} from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCollaborators = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/user/company/${id}`;
  dispatch({
    type: LOADING_COLLABORATORS,
  });
  const response = await getData(ERROR_COLLABORATORS, url, dispatch, true);
  if (response.data.users) {
    dispatch({
      type: GET_COLLABORATORS,
      payload: response.data.users,
    });
  }
};
