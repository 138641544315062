import React from 'react';
import { withWindowSize } from 'react-fns';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiQrCodeFill } from 'react-icons/ri';
import { TRIGGER_QRCODE } from '../../actions/actions';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import styles from './bottom-bar.module.scss';

const BottomBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const authReducer = useAppSelector((store) => store.authReducer);
  const type = authReducer.user?.company?.type;

  const navigate = useNavigate();

  function toggleModal() {
    dispatch({
      type: TRIGGER_QRCODE,
    });
    navigate('/qrcode');
  }

  function getQRButton() {
    let elts = null;
    if (type === 'fournisseur') {
      elts = (
        <NavLink to='/qrcode' className={({ isActive }) => (isActive ? styles.active : '')}>
          <button className={styles.buttonQRCode}>
            <RiQrCodeFill />
          </button>
        </NavLink>
      );
    } else if (type === 'magasin') {
      elts = (
        <NavLink to='/qrcode' className={({ isActive }) => (isActive ? styles.active : '')}>
          <button className={styles.buttonQRCode} onClick={() => toggleModal()}>
            <RiQrCodeFill />
          </button>
        </NavLink>
      );
    }
    return elts;
  }

  return (
    <>
      <div className={styles.bar}>
        <a className={styles.disabled}>
          <button className={`secondary ${styles.buttonNav}`}>
            Rendez-vous
          </button>
        </a>
        {getQRButton()}
        <NavLink to='/classement' className={({ isActive }) => (isActive ? styles.active : '')}>
          <button className={`secondary ${styles.buttonNav}`}>
            Classement
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default withWindowSize(BottomBar);
