import React, { useState, useEffect, createRef } from 'react';
import { withWindowSize } from 'react-fns';
// @ts-ignore
import QrReader from 'react-qr-reader';
import { RESET_TRIGGER_QRCODE, ERROR_VISIT } from '../../actions/actions';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import Loader from '../Loader';
import ModalStandalone from '../../lib/ModalComponents';
import styles from './modal-scanner.module.scss';
import { createVisit } from '../../actions/visit';

interface IModalStandalone {
  toggle(): void
  open(): void
  close(): void,
  isOpen(): boolean,
}

interface IModalScanner {
  width: number,
  height: number,
}

const ModalScanner = ({ width, height }: IModalScanner): JSX.Element => {
  const dispatch = useAppDispatch();
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const [isScannerReady, setIsScannerReady] = useState(false);
  const modalRef = createRef<IModalStandalone>();
  const scannerRef = createRef<HTMLDivElement>();
  const [data, setData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState<boolean | null>(null);

  // open QRCode Scanner
  useEffect(() => {
    if (visitsReducer.triggerQRCode) modalRef.current?.open();
    return () => {
      dispatch({
        type: RESET_TRIGGER_QRCODE,
      });
    };
  }, [visitsReducer.triggerQRCode]);

  useEffect(() => {
    // if (type !== 'magasin') return;
    if (validate) {
      modalRef.current?.close();
      setValidate(null);
      setData(null);
    }
  }, [validate]);

  function handleOpenQRCode() {
    const elt = scannerRef.current?.querySelector('section > section > div');
    if (elt) {
      let style = elt.getAttribute('style');
      if (style) {
        style = style.replace(
          'border: 50px solid rgba(0, 0, 0, 0.3); box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;',
          'border: 30px solid rgba(0, 0, 0, 0.3); box-shadow: rgba(0, 89, 182, 0.5) 0px 0px 0px 5px inset;',
        );
        elt.setAttribute('style', style);
      }
    }
  }

  function handleCloseQRCode() {
    dispatch({
      type: RESET_TRIGGER_QRCODE,
    });
  }

  function handleScan(d: string) {
    if (d) setData(d);
  }

  function handleError(err: any) {
    console.error(err);
  }

  function handleSubmit() {
    setIsLoading(true);
    createVisit(dispatch, {
      providerUser: data,
    }).catch((err) => {
      dispatch({
        type: ERROR_VISIT,
        payload: err,
      });
      setValidate(false);
      setIsLoading(false);
    }).then(() => {
      setValidate(true);
      setIsLoading(false);
    });
  }

  function getStyle() {
    let minSize = width < height ? width : height;
    if (minSize > 480) minSize = 450;
    else minSize -= 30;

    const style = {
      width: minSize,
      height: 'auto',
      opacity: 1,
    };
    if (!isScannerReady) style.opacity = 0;
    return style;
  }

  return (
    <ModalStandalone ref={modalRef} handleOpen={() => handleOpenQRCode()} handleClose={() => handleCloseQRCode()}>
      <>
        {(!isScannerReady) && <Loader />}
        <div ref={scannerRef} className={styles.scanner} style={getStyle()}>
          {(!isLoading && validate === null) && (
            <>
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                onLoad={() => setTimeout(() => setIsScannerReady(true), 1000)}
                showViewFinder={true}
                style={{ width: '100%', height: '100%' }}
              />
              <>
                {isScannerReady && (
                  <>
                    {data
                      ? <button onClick={handleSubmit}>Valider</button>
                      : <button className={styles.instruction}>
                        Scanner le QRCode du fournisseur
                      </button>
                    }
                  </>
                )
                }
              </>

            </>
          )}
          {(isLoading) && <Loader />}
          {validate === false && (
            <>
              <p className={styles.errorScan}>Oops ! Quelque chose<br />s'est mal passé...</p>
              <button onClick={() => setValidate(null)} >Scanner à nouveau</button>
            </>
          )}
        </div>
      </>
    </ModalStandalone>
  );
};

export default withWindowSize(ModalScanner);
