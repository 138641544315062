import { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { getProfileAction } from './actions/auth';

import SignIn from './pages/SignIn';
import Ranking from './pages/Ranking';
import Appointment from './pages/Appointment';
import QRCode from './pages/QRCode';
import Layout from './components/Layout';

import Loader from './components/Loader';
import Collaborators from './pages/Collaborators';

const Redirect = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch).catch(() => { navigate('/'); });
    else navigate('/');
  }, []);

  return (
    <Loader />
  );
};

const AppRoutes = () => {
  const authReducer = useAppSelector((state: any) => state.authReducer);
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      {authReducer.user && (
        <>
          <Route path="/classement" element={<Layout><Ranking /></Layout>} />
          <Route path="/qrcode" element={<Layout><QRCode /></Layout>} />
          <Route path="/rendez-vous" element={<Layout><Appointment /></Layout>} />
          <Route path="/collaborateurs" element={<Layout><Collaborators /></Layout>} />
        </>
      )}
      <Route path="*" element={<Redirect/>} />
    </Routes>
  );
};

export default AppRoutes;
