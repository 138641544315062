import React, { useEffect } from 'react';
import styles from './qrcode.module.scss';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { TRIGGER_QRCODE } from '../../actions/actions';
import { getUserBadge } from '../../actions/auth';
import ModalScanner from '../../components/ModalScanner';
import Loader from '../../components/Loader';

const QRCode = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const authReducer = useAppSelector((store) => store.authReducer);
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const { visit, error: visitError } = visitsReducer;
  const type = authReducer.user?.company?.type;

  useEffect(() => {
    if (type === 'fournisseur' && authReducer.user) getUserBadge(dispatch, authReducer.user._id);
  }, [authReducer.user]);

  function toggleQRCodeScanner() {
    dispatch({
      type: TRIGGER_QRCODE,
    });
  }

  console.log(visitError);

  return (
    <>
      <ModalScanner />
      <div className={styles.qrcode}>
        {type === 'fournisseur' && (
          <div className={`${styles.container} ${styles['container-badge']}`}>
            {authReducer.badge && (
              <img src={authReducer.badge} alt="" />
            )}
            {authReducer.loadingType === 'get_badge' && (
              <Loader />
            )}
          </div>
        )}
        {(visit && type === 'magasin') ? (
          <div className={`${styles.container} ${styles['container-status-scan']}`}>
            <p className={styles.providerName}>{visit?.provider.name}</p>
            {(visit.startDate && !visit.endDate) && (
              <p>Bienvenue sur notre stand, à la fin de votre visite, pensez à scanner de nouveau le QRCode du fournisseur.</p>
            )}
            {(visit.startDate && visit.endDate && !visit.completed) && (
              <p>Merci de votre visite et à très bientôt !</p>
            )}
            {(visit.startDate && visit.endDate && visit.completed) && (
              <p>Votre visite a déjà été enregistrée sur ce stand.</p>
            )}
          </div>) : (
          <div className={`${styles.container} ${styles['container-status-scan']}`}>
            <button onClick={() => toggleQRCodeScanner()}>Scanner le QRCode du fournisseur</button>
            {visitError && (
              <p className={styles.error}>{visitError}</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QRCode;
