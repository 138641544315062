/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const getDateAsString = (date : string): string => {
  let strDate = format(new Date(date), 'eeee d LLLL HH:mm', { locale: fr });
  strDate = strDate[0].toUpperCase() + strDate.slice(1, strDate.length);
  // Lundi 5 février 09:30
  return strDate;
};

export { getDateAsString };
